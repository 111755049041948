function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import { useMemo, useEffect, useRef, memo } from "react";
import shallowEqual from "shallowequal";
import update from "immutability-helper";
import { useTrackJumpHistoryMutation, ItemType, UserType } from "sprout-graphql";
import useCurrentUser from "@sprout/client-graphql/dist/useCurrentUser";
import { useJumpHistories } from "@sprout/context/dist/jumpHistories";
import active from "@sprout/client-lib/dist/active";
const TrackJumpHistory = (props)=>{
    const { data , type  } = props;
    const currentUser = useCurrentUser();
    const { jumpHistories , updateQuery  } = useJumpHistories();
    const [trackJumpHistory] = useTrackJumpHistoryMutation();
    const loadingRef = useRef(false);
    /**
   * This is to prevent tracking the same route over and over again.
   * So if current `jumpHistory` is similar to previous `jumpHistory`, we
   * ignore it
   */ const exists = useMemo(()=>{
        const [jumpHistory] = jumpHistories;
        if (!active(currentUser)) return true;
        if (!jumpHistory) return false;
        const { search  } = jumpHistory;
        return search.type === type && shallowEqual(search.data, data);
    }, [
        currentUser,
        data,
        jumpHistories,
        type
    ]);
    useEffect(()=>{
        if (exists) return;
        const track = function() {
            var _ref = _asyncToGenerator(function*() {
                var ref, ref1;
                let itemId;
                let userId;
                if ([
                    ItemType.Post,
                    ItemType.Topic,
                    ItemType.Checklist,
                    ItemType.ChecklistItem, 
                ].includes(type) && "itemId" in data) itemId = data.itemId;
                if (type === UserType.User || type === UserType.Team) userId = data.userId;
                const result = yield trackJumpHistory({
                    variables: {
                        search: {
                            type,
                            data
                        },
                        itemId,
                        userId
                    }
                });
                const searchHistory = (ref = result.data) === null || ref === void 0 ? void 0 : (ref1 = ref.addToSearchHistory) === null || ref1 === void 0 ? void 0 : ref1.searchHistory;
                if (searchHistory && updateQuery) updateQuery((prevResult)=>{
                    return update(prevResult, {
                        jumpHistories: {
                            nodes: {
                                $apply: (nodes)=>[
                                        searchHistory,
                                        ...nodes.filter((node)=>node.nodeId !== searchHistory.nodeId), 
                                    ]
                            }
                        }
                    });
                });
            });
            return function track() {
                return _ref.apply(this, arguments);
            };
        }();
        if (!loadingRef.current) {
            loadingRef.current = true;
            track().catch((error)=>{
                console.error(error);
            }).finally(()=>{
                loadingRef.current = false;
            });
        }
    }, [
        data,
        exists,
        trackJumpHistory,
        type,
        updateQuery
    ]);
    return null;
};
/**
 * Because we are passing in an object as prop, using memo here is preferred
 * to prevent this component from continuously running.
 */ export default memo(TrackJumpHistory, (prevProps, nextProps)=>{
    return shallowEqual(prevProps.data, nextProps.data) && prevProps.type === nextProps.type;
});
