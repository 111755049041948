import { Box } from "sprout-ui-primitives";
import { media, themeGet } from "sprout-ui-theme";
import styled, { css } from "styled-components";
const bgStyles = css`
  overflow: hidden;
  border-radius: 12px;
  background: ${themeGet("colors.white.100")};

  ${({ directChildren  })=>directChildren ? css`
          & > div {
            padding: 12px 16px;
          }
        ` : css`
          & > div > div {
            padding: 12px 16px;
          }
        `}
`;
const CardsWrapper = styled(Box)`
  ${media.down("medium")} {
    ${bgStyles}
  }

  ${media.target("touch")} {
    ${bgStyles}
  }

  ${({ directChildren  })=>directChildren ? css`
          & > div:first-of-type:not(:last-of-type) {
            ::before {
              display: none;
            }
          }

          & > div:last-of-type:not(:first-of-type) {
            ::after {
              display: none;
            }
          }
        ` : css`
          & > div:first-of-type:not(:last-of-type) > div {
            ::before {
              display: none;
            }
          }

          & > div:last-of-type:not(:first-of-type) > div {
            ::after {
              display: none;
            }
          }
        `}
`;
CardsWrapper.defaultProps = {
    directChildren: false
};
export default CardsWrapper;
