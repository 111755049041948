import { ItemMemberLevel } from "sprout-graphql";
function getPostAuthorization({ post , currentUserId  }) {
    const { rootTopicOrSelf , archivedAt , deletedAt , isExplicitlyDeleted , completed ,  } = post;
    const { memberLevel , owner  } = rootTopicOrSelf || {};
    const ownerId = owner === null || owner === void 0 ? void 0 : owner.id;
    const isOwnerOrAdmin = !!(memberLevel && [
        ItemMemberLevel.Owner,
        ItemMemberLevel.Admin
    ].includes(memberLevel));
    const isMineAsContributor = memberLevel === ItemMemberLevel.Contributor && ownerId === currentUserId;
    const trashOrArchived = deletedAt !== null || archivedAt !== null;
    return {
        addPost: (isOwnerOrAdmin || isMineAsContributor) && !trashOrArchived,
        edit: (isOwnerOrAdmin || isMineAsContributor) && !trashOrArchived,
        move: (isOwnerOrAdmin || isMineAsContributor) && !trashOrArchived,
        addTodoList: (isOwnerOrAdmin || isMineAsContributor) && !trashOrArchived,
        addTodo: (isOwnerOrAdmin || isMineAsContributor) && !trashOrArchived,
        addLabel: isOwnerOrAdmin || isMineAsContributor,
        assign: isOwnerOrAdmin,
        schedule: isOwnerOrAdmin && !trashOrArchived,
        // bookmark: currentUserId !== undefined,
        bookmark: isOwnerOrAdmin || isMineAsContributor,
        archive: (isOwnerOrAdmin || isMineAsContributor) && !deletedAt,
        trash: isOwnerOrAdmin || isMineAsContributor,
        delete: (isOwnerOrAdmin || isMineAsContributor) && isExplicitlyDeleted,
        viewActivity: (isOwnerOrAdmin || isMineAsContributor) && !trashOrArchived,
        // edge case for `Todo`
        convertToPost: (isOwnerOrAdmin || isMineAsContributor) && !trashOrArchived && !completed
    };
}
export default getPostAuthorization;
