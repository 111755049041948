import { keyframes } from "styled-components";
export const circleRadius = 25;
export const scaleRatio = circleRadius / 20;
export const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;
export const dashed = keyframes`
  0% {
    stroke-dasharray: ${scaleRatio * 1}, ${scaleRatio * 200};
    stroke-dashoffset: ${scaleRatio * 0};
  }

  50% {
    stroke-dasharray: ${scaleRatio * 89}, ${scaleRatio * 200};
    stroke-dashoffset: ${scaleRatio * -35};
  }

  100% {
    stroke-dasharray: ${scaleRatio * 89}, ${scaleRatio * 200};
    stroke-dashoffset: ${scaleRatio * -124};
  }
`;
