import styled from "styled-components";
import { Textarea } from "../Form";
const TextFieldTextarea = styled(Textarea)`
  padding: 13px 16px;

  border-radius: 8px;

  .error & {
    border-color: #ff1744;
  }
`;
export default TextFieldTextarea;
