import styled from "styled-components";
import { themeGet } from "sprout-ui-theme";
const PostPageCommentCount = styled.span`
  display: inline-block;

  padding: 0px 6px;

  min-width: 24px;

  text-align: center;
  color: ${themeGet("colors.white.100")};

  font-size: 12px;
  font-weight: ${themeGet("fontWeights.bold")};
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  line-height: 24px;

  background: ${themeGet("colors.blue.100")};
  border-radius: 12px;
  pointer-events: none;

  margin: 0 8px;
`;
export default PostPageCommentCount;
