import styled from "styled-components";
import { Input } from "../Form";
const TextFieldInput = styled(Input)`
  height: 48px;
  padding: 0 16px;

  border-radius: 8px;

  .error & {
    border-color: #ff1744;
  }
`;
export default TextFieldInput;
