function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import generateRandomKey from "@sprout/client-lib/dist/generateRandomKey";
import { useCallback } from "react";
import { ItemType, PostPageTodosAndTodoLists_ItemFragmentDoc, useArchiveItemMutation } from "sprout-graphql";
import { useSnackbar } from "sprout-ui-dom/dist/Snackbar";
import update from "immutability-helper";
export default function useArchiveTodoList() {
    const [archiveTodoListMutation] = useArchiveItemMutation();
    const showSnackbar = useSnackbar();
    return useCallback(({ variables , parentNodeId  })=>{
        const { itemId , isExplicitlyArchived  } = variables;
        archiveTodoListMutation({
            variables,
            optimisticResponse: {
                updateItemById: {
                    item: {
                        id: itemId,
                        nodeId: generateRandomKey(),
                        type: ItemType.Checklist,
                        archivedAt: new Date().toISOString(),
                        isExplicitlyArchived,
                        __typename: "Item"
                    },
                    __typename: "UpdateItemPayload"
                }
            },
            update (proxy, { data  }) {
                var ref;
                const item = data === null || data === void 0 ? void 0 : (ref = data.updateItemById) === null || ref === void 0 ? void 0 : ref.item;
                if (!item) return;
                if (isExplicitlyArchived) {
                    const options = {
                        id: parentNodeId,
                        fragment: PostPageTodosAndTodoLists_ItemFragmentDoc,
                        fragmentName: "PostPageTodosAndTodoLists_Item"
                    };
                    const cache = proxy.readFragment(options);
                    proxy.writeFragment(_objectSpread({}, options, {
                        data: update(cache, {
                            todoLists: {
                                nodes: {
                                    $apply: (nodes)=>nodes.filter(({ id  })=>id !== item.id)
                                }
                            }
                        })
                    }));
                }
            }
        }).then(()=>{
            showSnackbar({
                message: `To-do list ${isExplicitlyArchived ? "archived" : "unarchived"}`
            });
        }).catch(()=>{
            showSnackbar.error();
        });
    }, [
        archiveTodoListMutation,
        showSnackbar
    ]);
};
