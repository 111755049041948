import { useCallback } from "react";
import { getItemUrl } from "sprout-lib";
import { useClipboard } from "sprout-ui-dom/dist/hooks";
import { getIsLargePointerDevice } from "sprout-ui-dom/dist/utils";
import useArchiveTodoList from "../graphql/mutations/useArchiveTodoList";
import useTrashTodoList from "../graphql/mutations/useTrashTodoList";
import { usePostPageEditor } from "../PostPageEditorContext";
const todoListSupportedActions = [
    "ARCHIVE",
    "DELETE",
    "OPEN",
    "ADD_TODO",
    "EDIT_TODOLIST",
    "COPY_TO_CLIPBOARD", 
];
export default function useTodoListSingularAction(variables) {
    const { disabled , postNodeId  } = variables;
    const { copy  } = useClipboard();
    const { dispatch  } = usePostPageEditor();
    const archiveTodoList = useArchiveTodoList();
    const trashTodoList = useTrashTodoList();
    const todoListSingularAction = useCallback((command, activeItem)=>{
        if (!activeItem) return false;
        const variant = getIsLargePointerDevice() ? "desktop" : "mobile";
        const { id  } = activeItem;
        switch(command){
            case "ADD_TODO":
                if (disabled) return false;
                dispatch({
                    type: "SET_EDITOR",
                    payload: {
                        editor: "CREATE_TODO",
                        variant,
                        editorPosition: id
                    }
                });
                return true;
            case "EDIT_TODOLIST":
                if (disabled) return false;
                dispatch({
                    type: "SET_EDITOR",
                    payload: {
                        editor: "EDIT_TODOLIST",
                        editorPosition: id
                    }
                });
                return true;
            case "COPY_TO_CLIPBOARD":
                copy(`${process.env.ROOT_URL}${getItemUrl(activeItem)}`);
                return true;
            case "ARCHIVE":
                archiveTodoList({
                    variables: {
                        isExplicitlyArchived: true,
                        itemId: id
                    },
                    parentNodeId: postNodeId
                });
                return true;
            case "DELETE":
                trashTodoList({
                    variables: {
                        isDeleted: true,
                        itemId: id
                    },
                    parentNodeId: postNodeId
                });
                return true;
            default:
                return false;
        }
    }, [
        archiveTodoList,
        copy,
        disabled,
        dispatch,
        postNodeId,
        trashTodoList
    ]);
    return {
        todoListSupportedActions,
        todoListSingularAction
    };
};
