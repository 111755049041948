function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import generateRandomKey from "@sprout/client-lib/dist/generateRandomKey";
import { useCallback } from "react";
import { ItemType, PostPageTodoList_ItemFragmentDoc, usePostPage_SortTodoListMutation, PostPageTodosAndTodoLists_ItemFragmentDoc } from "sprout-graphql";
import immutableUpdate from "immutability-helper";
import reorder from "sprout-lib/dist/reorder";
import { useApolloClient } from "@apollo/client";
import { useSnackbar } from "sprout-ui-dom/dist/Snackbar";
function getNewIndex(nodes, variables) {
    const afterIndex = variables.afterItemId ? nodes.findIndex(({ id  })=>id === variables.afterItemId) : variables.afterLastItem ? nodes.length - 1 : -1;
    return afterIndex + 1;
}
function useSortTodoListWithOptimisticResponse() {
    const [sortTodoListMutation] = usePostPage_SortTodoListMutation();
    const showSnackbar = useSnackbar();
    return useCallback(({ variables , position , update  })=>{
        sortTodoListMutation({
            variables,
            context: {
                queue: true
            },
            optimisticResponse: {
                moveItemsByIdIntoTopic: {
                    items: [
                        {
                            id: variables.itemId,
                            nodeId: generateRandomKey(),
                            type: ItemType.ChecklistItem,
                            position,
                            __typename: "Item"
                        }, 
                    ],
                    __typename: "MoveItemsByIdIntoTopicPayload"
                }
            },
            update
        }).catch(()=>{
            showSnackbar.error();
        });
    }, [
        showSnackbar,
        sortTodoListMutation
    ]);
}
function getNewPosition(afterItem) {
    return afterItem && afterItem.position !== null ? afterItem.position + Number.MIN_VALUE : -Number.MAX_SAFE_INTEGER;
}
function sourceIsSameAsDestination({ todos , source , destination  }) {
    // Same logic used in `PostPageTodoList`
    const incompleteTodos = todos.filter((x)=>!x.completed && !(x.archivedAt || x.deletedAt));
    const afterItem = destination.index < source.index ? incompleteTodos[destination.index - 1] : incompleteTodos[destination.index];
    const afterItemId = (afterItem === null || afterItem === void 0 ? void 0 : afterItem.id) || null;
    return {
        position: getNewPosition(afterItem),
        afterItemId
    };
}
function sourceIsDifferentFromDestination({ todos , destination  }) {
    // Same logic used in `PostPageTodoList`
    const incompleteTodos = todos.filter((x)=>!x.completed && !(x.archivedAt || x.deletedAt));
    const afterItem = incompleteTodos[destination.index - 1];
    const afterItemId = (afterItem === null || afterItem === void 0 ? void 0 : afterItem.id) || null;
    return {
        position: getNewPosition(afterItem),
        afterItemId
    };
}
export default function useHandleDragEnd() {
    const sortTodoList = useSortTodoListWithOptimisticResponse();
    const client = useApolloClient();
    return useCallback(({ draggableId , type , source , destination  })=>{
        /**
       * don't sort if the item is dropped outside the droppable
       * don't sort if the item is touched but not moved
       */ if (!destination || destination.index === source.index && destination.droppableId === source.droppableId) return;
        const itemId = Number(draggableId);
        try {
            const parsedSource = JSON.parse(source.droppableId);
            const parsedDestination = JSON.parse(destination.droppableId);
            // Sorting `TodoList`
            if (type === ItemType.Checklist) {
                const options = {
                    id: parsedDestination.nodeId,
                    fragment: PostPageTodosAndTodoLists_ItemFragmentDoc,
                    fragmentName: "PostPageTodosAndTodoLists_Item"
                };
                const item = client.readFragment(options);
                if (!item) return;
                const todoLists = item.todoLists.nodes;
                const afterItem = destination.index < source.index ? todoLists[destination.index - 1] : todoLists[destination.index];
                const position = getNewPosition(afterItem);
                const afterItemId = (afterItem === null || afterItem === void 0 ? void 0 : afterItem.id) || null;
                sortTodoList({
                    variables: {
                        itemId,
                        afterItemId,
                        parentTopicId: parsedDestination.id
                    },
                    position,
                    update (proxy) {
                        proxy.writeFragment(_objectSpread({}, options, {
                            data: immutableUpdate(item, {
                                todoLists: {
                                    nodes: {
                                        $apply: (nodes)=>{
                                            const oldIndex = nodes.findIndex(({ id  })=>id === itemId);
                                            const newIndex = getNewIndex(nodes, {
                                                afterItemId
                                            });
                                            return reorder(nodes, oldIndex, newIndex);
                                        }
                                    }
                                }
                            })
                        }));
                    }
                });
                return;
            }
            if (type === ItemType.ChecklistItem) {
                /**
           * Moving between same `TodoList` or
           * Moving between orphan todos
           */ if (parsedSource.nodeId === parsedDestination.nodeId) {
                    // Moving between same `TodoList`
                    if (parsedDestination.type === ItemType.Checklist) {
                        const options = {
                            id: parsedDestination.nodeId,
                            fragment: PostPageTodoList_ItemFragmentDoc,
                            fragmentName: "PostPageTodoList_Item"
                        };
                        const todoList = client.readFragment(options);
                        if (!todoList) return;
                        const { afterItemId , position  } = sourceIsSameAsDestination({
                            todos: todoList.todos.nodes,
                            destination,
                            source
                        });
                        sortTodoList({
                            variables: {
                                afterItemId,
                                parentTopicId: parsedDestination.id,
                                itemId
                            },
                            position,
                            update (proxy) {
                                proxy.writeFragment(_objectSpread({}, options, {
                                    data: immutableUpdate(todoList, {
                                        todos: {
                                            nodes: {
                                                $apply: (nodes)=>{
                                                    const oldIndex = nodes.findIndex(({ id  })=>id === itemId);
                                                    const newIndex = getNewIndex(nodes, {
                                                        afterItemId
                                                    });
                                                    return reorder(nodes, oldIndex, newIndex);
                                                }
                                            }
                                        }
                                    })
                                }));
                            }
                        });
                    // Moving between orphan todos
                    } else if (parsedDestination.type === ItemType.Post) {
                        const options = {
                            id: parsedDestination.nodeId,
                            fragment: PostPageTodosAndTodoLists_ItemFragmentDoc,
                            fragmentName: "PostPageTodosAndTodoLists_Item"
                        };
                        const item = client.readFragment(options);
                        if (!item) return;
                        const { afterItemId , position  } = sourceIsSameAsDestination({
                            todos: item.todos.nodes,
                            destination,
                            source
                        });
                        sortTodoList({
                            variables: {
                                afterItemId,
                                parentTopicId: parsedDestination.id,
                                itemId
                            },
                            position,
                            update (proxy) {
                                proxy.writeFragment(_objectSpread({}, options, {
                                    data: immutableUpdate(item, {
                                        todos: {
                                            nodes: {
                                                $apply: (nodes)=>{
                                                    const oldIndex = nodes.findIndex(({ id  })=>id === itemId);
                                                    const newIndex = getNewIndex(nodes, {
                                                        afterItemId
                                                    });
                                                    return reorder(nodes, oldIndex, newIndex);
                                                }
                                            }
                                        }
                                    })
                                }));
                            }
                        });
                    }
                    return;
                } else if (parsedSource.type === ItemType.Checklist) {
                    const sourceOptions = {
                        id: parsedSource.nodeId,
                        fragment: PostPageTodoList_ItemFragmentDoc,
                        fragmentName: "PostPageTodoList_Item"
                    };
                    const sourceTodoList = client.readFragment(sourceOptions);
                    const todo = sourceTodoList === null || sourceTodoList === void 0 ? void 0 : sourceTodoList.todos.nodes.find(({ id  })=>id === itemId);
                    if (!todo) return;
                    // Moving from `TodoList` to `TodoList`
                    if (parsedDestination.type === ItemType.Checklist) {
                        const destinationOptions = {
                            id: parsedDestination.nodeId,
                            fragment: PostPageTodoList_ItemFragmentDoc,
                            fragmentName: "PostPageTodoList_Item"
                        };
                        const destinationTodoList = client.readFragment(destinationOptions);
                        if (!destinationTodoList) return;
                        const { afterItemId , position  } = sourceIsDifferentFromDestination({
                            todos: destinationTodoList.todos.nodes,
                            destination
                        });
                        sortTodoList({
                            variables: {
                                itemId,
                                afterItemId,
                                parentTopicId: parsedDestination.id
                            },
                            position,
                            update (proxy) {
                                // Remove `Todo` from `TodoList`
                                proxy.writeFragment(_objectSpread({}, sourceOptions, {
                                    data: immutableUpdate(sourceTodoList, {
                                        todos: {
                                            nodes: {
                                                $apply: (nodes)=>nodes.filter(({ id  })=>id !== itemId)
                                            }
                                        }
                                    })
                                }));
                                proxy.writeFragment(_objectSpread({}, destinationOptions, {
                                    data: immutableUpdate(destinationTodoList, {
                                        todos: {
                                            nodes: {
                                                $apply: (nodes)=>{
                                                    const match = nodes.find((node)=>node.id === todo.id);
                                                    // scenario where the result is already returned via subscriptions
                                                    if (match) return nodes;
                                                    const newIndex = getNewIndex(nodes, {
                                                        afterItemId
                                                    });
                                                    return [
                                                        ...nodes.slice(0, newIndex),
                                                        todo,
                                                        ...nodes.slice(newIndex), 
                                                    ];
                                                }
                                            }
                                        }
                                    })
                                }));
                            }
                        });
                    }
                    if (parsedDestination.type === ItemType.Post) {
                        const destinationOptions = {
                            id: parsedDestination.nodeId,
                            fragment: PostPageTodosAndTodoLists_ItemFragmentDoc,
                            fragmentName: "PostPageTodosAndTodoLists_Item"
                        };
                        const item = client.readFragment(destinationOptions);
                        if (!item) return;
                        const { afterItemId , position  } = sourceIsDifferentFromDestination({
                            todos: item.todos.nodes,
                            destination
                        });
                        sortTodoList({
                            variables: {
                                itemId,
                                parentTopicId: parsedDestination.id,
                                afterItemId
                            },
                            position,
                            update (proxy) {
                                // Remove `Todo` from `TodoList`
                                proxy.writeFragment(_objectSpread({}, sourceOptions, {
                                    data: immutableUpdate(sourceTodoList, {
                                        todos: {
                                            nodes: {
                                                $apply: (nodes)=>nodes.filter(({ id  })=>id !== itemId)
                                            }
                                        }
                                    })
                                }));
                                proxy.writeFragment(_objectSpread({}, destinationOptions, {
                                    data: immutableUpdate(item, {
                                        todos: {
                                            nodes: {
                                                $apply: (nodes)=>{
                                                    const match = nodes.find((node)=>node.id === todo.id);
                                                    // scenario where the result is already returned via subscriptions
                                                    if (match) return nodes;
                                                    const newIndex = getNewIndex(nodes, {
                                                        afterItemId
                                                    });
                                                    return [
                                                        ...nodes.slice(0, newIndex),
                                                        todo,
                                                        ...nodes.slice(newIndex), 
                                                    ];
                                                }
                                            }
                                        }
                                    })
                                }));
                            }
                        });
                    }
                // Moving from orphan todos
                } else {
                    const sourceOptions = {
                        id: parsedSource.nodeId,
                        fragment: PostPageTodosAndTodoLists_ItemFragmentDoc,
                        fragmentName: "PostPageTodosAndTodoLists_Item"
                    };
                    const sourceItem = client.readFragment(sourceOptions);
                    const todo = sourceItem === null || sourceItem === void 0 ? void 0 : sourceItem.todos.nodes.find(({ id  })=>id === itemId);
                    if (!todo) return;
                    // Moving from orphan todos to `TodoList`
                    if (parsedDestination.type === ItemType.Checklist) {
                        const destinationOptions = {
                            id: parsedDestination.nodeId,
                            fragment: PostPageTodoList_ItemFragmentDoc,
                            fragmentName: "PostPageTodoList_Item"
                        };
                        const destinationTodoList = client.readFragment(destinationOptions);
                        if (!destinationTodoList) return;
                        const { afterItemId , position  } = sourceIsDifferentFromDestination({
                            todos: destinationTodoList.todos.nodes,
                            destination
                        });
                        sortTodoList({
                            variables: {
                                itemId,
                                afterItemId,
                                parentTopicId: parsedDestination.id
                            },
                            position,
                            update (proxy) {
                                // Remove `Todo` from `TodoList`
                                proxy.writeFragment(_objectSpread({}, sourceOptions, {
                                    data: immutableUpdate(sourceItem, {
                                        todos: {
                                            nodes: {
                                                $apply: (nodes)=>nodes.filter(({ id  })=>id !== itemId)
                                            }
                                        }
                                    })
                                }));
                                proxy.writeFragment(_objectSpread({}, destinationOptions, {
                                    data: immutableUpdate(destinationTodoList, {
                                        todos: {
                                            nodes: {
                                                $apply: (nodes)=>{
                                                    const match = nodes.find((node)=>node.id === todo.id);
                                                    // scenario where the result is already returned via subscriptions
                                                    if (match) return nodes;
                                                    const newIndex = getNewIndex(nodes, {
                                                        afterItemId
                                                    });
                                                    return [
                                                        ...nodes.slice(0, newIndex),
                                                        todo,
                                                        ...nodes.slice(newIndex), 
                                                    ];
                                                }
                                            }
                                        }
                                    })
                                }));
                            }
                        });
                    }
                }
            }
        } catch (e) {
            console.error("Unexpected error while parsing `droppableId`");
        }
    }, [
        client,
        sortTodoList
    ]);
};
