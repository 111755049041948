import { useCallback } from "react";
import { ItemType, useTopic_StarTopicMutation } from "sprout-graphql";
import { useSnackbar } from "sprout-ui-dom/dist/Snackbar";
function useStarTopic({ nodeId , topicId , star  }) {
    const showSnackbar = useSnackbar();
    const [starTopic] = useTopic_StarTopicMutation({
        variables: {
            star,
            topicId
        },
        optimisticResponse: {
            starTopic: {
                query: {
                    nodeId: "query",
                    itemById: {
                        nodeId,
                        id: topicId,
                        type: ItemType.Topic,
                        currentUserHasStarred: star,
                        __typename: "Item"
                    },
                    __typename: "Query"
                },
                __typename: "StarTopicPayload"
            }
        }
    });
    return useCallback(()=>{
        starTopic().catch(()=>{
            showSnackbar.error();
        });
    }, [
        showSnackbar,
        starTopic
    ]);
}
export default useStarTopic;
