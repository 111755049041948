import { createContainer } from "unstated-next";
import { useState, useRef } from "react";
export var FocusFrom;
(function(FocusFrom) {
    FocusFrom[FocusFrom["NOTE"] = 0] = "NOTE";
    FocusFrom[FocusFrom["DESCRIPTION"] = 1] = "DESCRIPTION";
    FocusFrom[FocusFrom["TODO"] = 2] = "TODO";
    FocusFrom[FocusFrom["COMMENT"] = 3] = "COMMENT";
})(FocusFrom || (FocusFrom = {}));
const PostPageFocusContext = createContainer(()=>{
    const [focusFrom, setFocusFrom] = useState(null);
    const focusFromRef = useRef(focusFrom);
    return [
        focusFrom,
        setFocusFrom,
        focusFromRef
    ];
});
export const usePostPageFocus = PostPageFocusContext.useContainer;
export const PostPageFocusProvider = PostPageFocusContext.Provider;
