import { useCallback } from "react";
import { useDeleteItemsMutation } from "sprout-graphql";
import { useSnackbar } from "sprout-ui-dom/dist/Snackbar";
export default function useDeleteItems() {
    const [deleteItems] = useDeleteItemsMutation();
    const showSnackbar = useSnackbar();
    return useCallback(({ itemIds , callback , type  })=>{
        deleteItems({
            variables: {
                itemIds
            }
        }).then(({ data  })=>{
            var ref;
            const items = data === null || data === void 0 ? void 0 : (ref = data.deleteItemsByIds) === null || ref === void 0 ? void 0 : ref.items;
            if (items) {
                if (callback) callback(items.map((item)=>item.id));
                showSnackbar({
                    message: `${type} permanently deleted`
                });
            }
        }).catch(()=>{
            showSnackbar.error();
        });
    }, [
        deleteItems,
        showSnackbar
    ]);
};
