import { Flex } from "sprout-ui-primitives";
import { media } from "sprout-ui-theme";
import styled, { css } from "styled-components";
const PageToolbar = styled(Flex)`
  position: sticky;
  z-index: 6;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);

  > * + * {
    margin-left: 4px;
  }

  ${media.up("medium", "pointer")} {
    bottom: 16px;
  }

  ${({ $stickyWithBottomNavigation  })=>$stickyWithBottomNavigation && css`
      margin-top: auto;
      margin-bottom: -48px;

      ${media.down("medium")} {
        bottom: 104px;
      }

      ${media.target("touch")} {
        bottom: 104px;
      }
    `}

  ${({ $immediateBottom  })=>$immediateBottom && css`
      margin-top: auto;
      margin-bottom: -48px;

      ${media.down("medium")} {
        bottom: 16px;
      }

      ${media.target("touch")} {
        bottom: 16px;
      }
    `}
`;
PageToolbar.defaultProps = {
    bg: "blue.40",
    borderRadius: "14px",
    p: 0,
    overflow: "hidden",
    alignSelf: "center"
};
export default PageToolbar;
