import { useSetItemsStarred } from "@sprout/client-graphql";
import useCurrentUser from "@sprout/client-graphql/dist/useCurrentUser";
import { useNavigationListContext } from "@sprout/list-navigation";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { ItemType } from "sprout-graphql";
import { getItemUrl } from "sprout-lib";
import { useClipboard } from "sprout-ui-dom/dist/hooks";
import getPostAuthorization from "./getPostAuthorization";
export const BASE_ACTIONS = [
    "OPEN",
    "OPEN_EXTERNAL",
    //
    "COPY_TO_CLIPBOARD",
    "VIEW_ITEM_ACTIVITY",
    //
    "REMOVE_PRIORITY",
    "SET_PRIORITY", 
];
export function usePostSingularAction(extendedFn) {
    const { copy  } = useClipboard();
    const currentUser = useCurrentUser();
    const currentUserId = currentUser === null || currentUser === void 0 ? void 0 : currentUser.id;
    const history = useHistory();
    const { multiselectCoords  } = useNavigationListContext();
    const isMultiselectActive = !!multiselectCoords;
    const setItemsStarred = useSetItemsStarred();
    return useCallback((command, activeItem, event)=>{
        if (extendedFn && extendedFn(command, activeItem, event)) return true;
        if (!activeItem || ![
            ItemType.Post,
            ItemType.ChecklistItem
        ].includes(activeItem.type)) return false;
        const authorization = getPostAuthorization({
            post: activeItem,
            currentUserId
        });
        switch(command){
            case "COPY_TO_CLIPBOARD":
                copy(`${process.env.ROOT_URL}${getItemUrl(activeItem)}`);
                return true;
            case "VIEW_ITEM_ACTIVITY":
                if (activeItem.type === ItemType.ChecklistItem) return false;
                if (authorization.viewActivity) {
                    history.push(`${getItemUrl(activeItem)}/activity`);
                    return true;
                }
                return false;
            case "REMOVE_PRIORITY":
            case "SET_PRIORITY":
                if (!currentUserId || isMultiselectActive) return false;
                setItemsStarred({
                    items: [
                        activeItem
                    ],
                    starred: command === "SET_PRIORITY"
                });
                return true;
            default:
                return false;
        }
    }, [
        copy,
        currentUserId,
        extendedFn,
        history,
        isMultiselectActive,
        setItemsStarred, 
    ]);
}
