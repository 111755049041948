import { usePostAuthorization } from "@sprout/client-components/dist/Post";
import { useActionRegistryGlobalHandler } from "@sprout/list-navigation";
import { useCallback, useMemo } from "react";
import { useSetItemsArchived, useSetItemsDeleted } from "@sprout/client-graphql";
import useDeleteItems from "@sprout/client-graphql/dist/mutations/useDeleteItems";
import { ItemType } from "sprout-graphql";
import { useGoBack } from "@sprout/providers/dist/goBack";
export default function usePostPageToolbarMenu(item) {
    const authorization = usePostAuthorization(item);
    const { type , id , isExplicitlyDeleted , isExplicitlyArchived  } = item;
    const displayStr = useMemo(()=>{
        if (type === ItemType.Checklist) return "To-do list";
        if (type === ItemType.ChecklistItem) return "To-do";
        return "Leaf";
    }, [
        type
    ]);
    const { goBack  } = useGoBack();
    //
    const setItemsArchived = useSetItemsArchived();
    useActionRegistryGlobalHandler("PAGE_TOGGLE_ARCHIVE", useCallback(()=>{
        const nextIsExplicitlyArchived = !isExplicitlyArchived;
        setItemsArchived({
            items: [
                item
            ],
            isExplicitlyArchived: nextIsExplicitlyArchived,
            callback () {
                if (nextIsExplicitlyArchived) goBack();
            }
        });
        return true;
    }, [
        goBack,
        isExplicitlyArchived,
        item,
        setItemsArchived
    ]), {
        skip: !authorization.archive
    });
    //
    const setItemsDeleted = useSetItemsDeleted();
    useActionRegistryGlobalHandler("PAGE_TOGGLE_DELETE", useCallback(()=>{
        const nextIsExplicitlyDeleted = !isExplicitlyDeleted;
        setItemsDeleted({
            items: [
                item
            ],
            isExplicitlyDeleted: nextIsExplicitlyDeleted,
            callback () {
                if (nextIsExplicitlyDeleted) goBack();
            }
        });
        return true;
    }, [
        goBack,
        isExplicitlyDeleted,
        item,
        setItemsDeleted
    ]), {
        skip: !authorization.trash
    });
    //
    const deleteItems = useDeleteItems();
    useActionRegistryGlobalHandler("PAGE_PERMANENT_DELETE", useCallback(()=>{
        deleteItems({
            itemIds: [
                id
            ],
            type: displayStr,
            callback () {
                goBack();
            }
        });
        return true;
    }, [
        deleteItems,
        displayStr,
        goBack,
        id
    ]), {
        skip: !authorization.delete
    });
};
