import { useMemo, useCallback, useRef, useLayoutEffect, useEffect, useState } from "react";
import { useNavigationActiveLock, useNavigationListContext } from "@sprout/list-navigation";
import { bindAll } from "bind-event-listener";
function getDraggingBindings(actions, stop) {
    const cancel = ()=>{
        stop();
        actions.cancel();
    };
    const drop = ()=>{
        stop();
        actions.drop();
    };
    return [
        {
            type: "keyup",
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            listener (event) {
                if (event.key === " ") {
                    event.preventDefault();
                    drop();
                }
            }
        },
        {
            type: "keydown",
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            listener (event) {
                switch(event.key){
                    case "Escape":
                        event.preventDefault();
                        cancel();
                        break;
                    case "j":
                    case "ArrowDown":
                        event.preventDefault();
                        actions.moveDown();
                        break;
                    case "k":
                    case "ArrowUp":
                        event.preventDefault();
                        actions.moveUp();
                        break;
                    case " ":
                    case "PageUp":
                    case "PageDown":
                    case "Home":
                    case "End":
                    case "Enter":
                    case "Tab":
                        event.preventDefault();
                        break;
                    default:
                        break;
                }
            }
        },
        {
            type: "mousedown",
            listener: cancel
        },
        {
            type: "mouseup",
            listener: cancel
        },
        {
            type: "click",
            listener: cancel
        },
        {
            type: "touchstart",
            listener: cancel
        },
        // resizing the browser kills a drag
        {
            type: "resize",
            listener: cancel
        },
        {
            type: "wheel",
            listener: cancel,
            // chrome says it is a violation for this to not be passive
            // it is fine for it to be passive as we just cancel as soon as we get
            // any event
            options: {
                passive: true
            }
        },
        // Cancel on page visibility change
        {
            type: "visibilitychange",
            listener: cancel
        }, 
    ];
}
const noop = ()=>{
// do nothing
};
export default function useCustomKeyboardSensor(api) {
    const unbindEventsRef = useRef(noop);
    const { activeCoord , multiselectCoords  } = useNavigationListContext();
    const listItemId = activeCoord === null || activeCoord === void 0 ? void 0 : activeCoord.listItemId;
    const listItemIdRef = useRef(listItemId);
    useEffect(()=>{
        listItemIdRef.current = listItemId;
    }, [
        listItemId
    ]);
    const multiselectActive = !!multiselectCoords;
    const multiselectActiveRef = useRef(multiselectActive);
    useEffect(()=>{
        multiselectActiveRef.current = multiselectActive;
    }, [
        multiselectActive
    ]);
    const [lock, setLock] = useState(false);
    useNavigationActiveLock(lock);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const startCaptureBinding = useMemo(()=>({
            type: "keydown",
            listener (event) {
                if (event.defaultPrevented) return;
                if (event.key !== " ") return;
                // disable if multiselect is active
                if (multiselectActiveRef.current) return;
                const draggableId = listItemIdRef.current;
                if (!draggableId) return;
                const stop = ()=>{
                    unbindEventsRef.current();
                    // restore `ListNavigation`
                    setLock(false);
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    listenForCapture();
                };
                const preDrag = api.tryGetLock(draggableId, stop, {
                    sourceEvent: event
                });
                if (!preDrag) return;
                // consuming event
                event.preventDefault();
                // disable `ListNavigation`
                setLock(true);
                const actions = preDrag.snapLift();
                // unbind this listener
                unbindEventsRef.current();
                unbindEventsRef.current = bindAll(window, getDraggingBindings(actions, stop), {
                    capture: true,
                    passive: false
                });
            }
        }), // eslint-disable-next-line react-hooks/exhaustive-deps
    [
        api
    ]);
    const listenForCapture = useCallback(()=>{
        unbindEventsRef.current = bindAll(window, [
            startCaptureBinding
        ], {
            capture: true,
            passive: false
        });
    }, [
        startCaptureBinding
    ]);
    useLayoutEffect(()=>{
        listenForCapture();
        return ()=>{
            unbindEventsRef.current();
        };
    }, [
        listenForCapture
    ]);
};
