import { useCallback } from "react";
import { useArchiveItemMutation, ItemType } from "sprout-graphql";
import { useSnackbar } from "sprout-ui-dom/dist/Snackbar";
import generateRandomKey from "@sprout/client-lib/dist/generateRandomKey";
function useArchiveTodo() {
    const showSnackbar = useSnackbar();
    const [archiveTodoMutation] = useArchiveItemMutation();
    const archiveTodo = useCallback(({ variables  })=>{
        const { isExplicitlyArchived , itemId  } = variables;
        archiveTodoMutation({
            variables,
            optimisticResponse: {
                updateItemById: {
                    item: {
                        id: itemId,
                        nodeId: generateRandomKey(),
                        type: ItemType.ChecklistItem,
                        archivedAt: new Date().toISOString(),
                        isExplicitlyArchived,
                        __typename: "Item"
                    },
                    __typename: "UpdateItemPayload"
                }
            }
        }).then(()=>{
            showSnackbar({
                message: `To-do ${isExplicitlyArchived ? "archived" : "unarchived"}`
            });
        }).catch((error)=>{
            console.log(error);
            showSnackbar.error();
        });
    }, [
        archiveTodoMutation,
        showSnackbar
    ]);
    return archiveTodo;
}
export default useArchiveTodo;
