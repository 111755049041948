import { useEffect, useRef } from "react";
import { startTask, abortAllTasks } from "@sprout/client-lib/dist/loading";
function useLoadingProgress(loading, taskLabel = "untitled useLoadingProgress") {
    const mount = useRef(false);
    useEffect(()=>{
        if (loading) return startTask(taskLabel);
        else if (!mount.current) {
            mount.current = true;
            abortAllTasks("no loading status due to partially cached query");
        }
        return undefined;
    }, [
        loading,
        taskLabel
    ]);
}
export default useLoadingProgress;
