import { useSetItemsStarred } from "@sprout/client-graphql";
import { useActionRegistryGlobalHandler } from "@sprout/list-navigation";
import { useCallback } from "react";
export default function useSetItemsStarredGlobalHandler() {
    const setItemStarred = useSetItemsStarred();
    useActionRegistryGlobalHandler([
        "SET_PRIORITY",
        "REMOVE_PRIORITY"
    ], useCallback((items, _event, command)=>{
        setItemStarred({
            items: [
                ...items
            ],
            starred: command === "SET_PRIORITY"
        });
        return true;
    }, [
        setItemStarred
    ]));
};
