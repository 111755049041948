import { useActionRegistryGlobalHandler } from "@sprout/list-navigation";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FocusFrom, usePostPageFocus } from "../PostPageFocusContext";
import { usePostPage } from "../PostPageContext";
import useFocusNoteEditor from "./useFocusNoteEditor";
export default function usePostPageNote({ note  }) {
    const { disabled  } = usePostPage();
    const [isNoteBlank, setIsNoteBlank] = useState(!note);
    const [focusFrom] = usePostPageFocus();
    const focusNote = useFocusNoteEditor();
    useActionRegistryGlobalHandler("FOCUS_NOTE", useCallback(()=>{
        focusNote();
        return true;
    }, [
        focusNote
    ]), {
        skip: disabled,
        preventDefault: true
    });
    const { state , pathname  } = useLocation();
    const history = useHistory();
    useEffect(()=>{
        // f === 'd' comes from the extension
        let timeoutId;
        if (state && state.focusNoteEditor) timeoutId = setTimeout(()=>{
            focusNote();
            // remove state from location, because state persist across browser refresh
            history.replace(pathname);
        });
        return ()=>{
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [
        focusNote,
        history,
        pathname,
        state
    ]);
    return {
        setIsNoteBlank,
        renderNote: note || focusFrom === FocusFrom.NOTE || !isNoteBlank
    };
};
