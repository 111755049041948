import { useCurrentUser } from "@sprout/client-graphql";
import { generateRandomKey } from "@sprout/client-lib";
import { useActionRegistryGlobalHandler } from "@sprout/list-navigation";
import { useCallback } from "react";
import { ItemFocusWhen, useSetItemsFocusWhenMutation } from "sprout-graphql";
import { useSnackbar } from "sprout-ui-dom/dist/Snackbar";
export default function useFocusWhenGlobalHandler(args = {
    disabled: false
}) {
    const { disabled  } = args;
    const [setItemsFocusWhen] = useSetItemsFocusWhenMutation();
    const showSnackbar = useSnackbar();
    const currentUser = useCurrentUser();
    useActionRegistryGlobalHandler([
        "SET_SET_ASIDE",
        "SET_DONE"
    ], useCallback((items, _event, command)=>{
        items.length;
        const focusWhen = command === "SET_SET_ASIDE" ? ItemFocusWhen.Later : null;
        setItemsFocusWhen({
            optimisticResponse: {
                upsertUserItemPreferences: {
                    userItemPreferences: items.map((item)=>{
                        var ref;
                        return {
                            focusWhen,
                            nodeId: ((ref = item.userPreferences) === null || ref === void 0 ? void 0 : ref.nodeId) || generateRandomKey()
                        };
                    })
                }
            },
            variables: {
                userItemPreferences: items.map((item)=>({
                        itemId: item.id,
                        focusWhen
                    }))
            }
        }).catch(()=>{
            showSnackbar.error();
        });
        return true;
    }, [
        setItemsFocusWhen,
        showSnackbar
    ]), {
        skip: disabled || !currentUser
    });
};
