export function isToday(date) {
    const today = new Date(Date.now());
    today.setHours(0, 0, 0, 0);
    const givenDate = new Date(date);
    givenDate.setHours(0, 0, 0, 0);
    return today.getTime() === givenDate.getTime();
}
export function isYesterday(date) {
    const yesterday = new Date();
    yesterday.setHours(0, 0, 0, 0);
    yesterday.setDate(yesterday.getDate() - 1);
    const givenDate = new Date(date);
    givenDate.setHours(0, 0, 0, 0);
    return yesterday.getTime() === givenDate.getTime();
}
