import { useCurrentUser, useSetItemsArchived, useSetItemsDeleted } from "@sprout/client-graphql";
import { useActionRegistryGlobalHandler } from "@sprout/list-navigation";
import { useCallback } from "react";
import { ItemType } from "sprout-graphql";
export default function useArchiveDeleteGlobalHandler(args = {
    disabled: false
}) {
    const { disabled  } = args;
    const currentUser = useCurrentUser();
    const setItemsArchived = useSetItemsArchived();
    const setItemsDeleted = useSetItemsDeleted();
    useActionRegistryGlobalHandler([
        "ARCHIVE",
        "DELETE"
    ], useCallback((items, _event, command)=>{
        items.length;
        const [item] = items;
        switch(command){
            case "ARCHIVE":
                setItemsArchived({
                    items: [
                        ...items
                    ],
                    isExplicitlyArchived: true,
                    snackbarOverwrite: item.type === ItemType.Topic ? items.length === 1 ? "Branch" : "Branches" : undefined
                });
                return true;
            case "DELETE":
                setItemsDeleted({
                    items: [
                        ...items
                    ],
                    isExplicitlyDeleted: true,
                    snackbarOverwrite: item.type === ItemType.Topic ? items.length === 1 ? "Branch" : "Branches" : undefined
                });
                return true;
            default:
                return false;
        }
    }, [
        setItemsArchived,
        setItemsDeleted
    ]), {
        skip: disabled || !currentUser
    });
};
