const YOUTUBEMATCH_URL = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
const VIMEOMATCH_URL = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
const YOUTUBE_PREFIX = "https://www.youtube.com/embed/";
const VIMEO_PREFIX = "https://player.vimeo.com/video/";
const sites = [
    {
        pattern: YOUTUBEMATCH_URL,
        prefix: YOUTUBE_PREFIX,
        index: 1
    },
    {
        pattern: VIMEOMATCH_URL,
        prefix: VIMEO_PREFIX,
        index: 3
    }, 
];
export default function isSproutSupportedVideo(url) {
    return sites.reduce((acc, currentValue)=>{
        const match = currentValue.pattern.exec(url);
        if (match) return `${currentValue.prefix}${match[currentValue.index]}`;
        return acc;
    }, undefined);
};
