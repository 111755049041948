import { generateRandomKey, generateOptimisticId } from "@sprout/client-lib";
import { ItemType } from "sprout-graphql";
const optimisticPost = {
    when: null,
    whenReminder: null,
    deadline: null,
    currentUserHasStarred: null,
    assignments: [],
    parentTopicId: null,
    nodeId: generateRandomKey(),
    id: generateOptimisticId(),
    type: ItemType.Post,
    isDraft: false,
    linkTitle: null,
    url: null,
    description: null,
    linkImage: null,
    note: null,
    websiteName: null,
    linkLogo: null,
    linkDescription: null,
    appliedLabels: [],
    archivedAt: null,
    isArchived: false,
    isExplicitlyArchived: false,
    deletedAt: null,
    isExplicitlyDeleted: false,
    completed: false,
    checklistItemCompletedCount: 0,
    checklistItemTotalCount: 0,
    commentCount: 0,
    rootTopicOrSelf: null,
    parentTopic: null,
    __typename: "Item",
    owner: null,
    title: null,
    userPreferences: null
};
export default optimisticPost;
