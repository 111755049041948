import { casual } from "chrono-node";
import { today, tomorrow } from "chrono-node/dist/common/casualReferences";
const chrono = casual.clone();
chrono.parsers.push({
    pattern () {
        return /^(to|tod|toda)$/i;
    },
    extract (context) {
        return today(context.reference);
    }
}, {
    pattern () {
        return /^(tom|tomo|tomor|tomorr|tomorro)$/i;
    },
    extract (context) {
        return tomorrow(context.reference);
    }
});
export default chrono;
