import { getItemUrl } from "sprout-lib";
import { useActionRegistryNavigation } from "@sprout/list-navigation";
function useTopicPageShortcuts({ owner , topic  }) {
    /**
   * `TopicPage` shortcuts
   */ const rootURL = getItemUrl(topic, owner);
    const skip = rootURL === "/";
    useActionRegistryNavigation("VIEW_ACTIVITY", `${rootURL}/activity`, {
        skip
    });
    useActionRegistryNavigation("VIEW_TRASH", `${rootURL}/trash`, {
        skip
    });
    useActionRegistryNavigation("VIEW_ARCHIVE", `${rootURL}/archive`, {
        skip
    });
    useActionRegistryNavigation("VIEW_MEMBERS", `${rootURL}/members`, {
        skip
    });
}
export default useTopicPageShortcuts;
