import { useCallback } from "react";
import { ItemType, useTrashItemMutation } from "sprout-graphql";
import { useSnackbar } from "sprout-ui-dom/dist/Snackbar";
import generateRandomKey from "@sprout/client-lib/dist/generateRandomKey";
function useDeleteTodo() {
    const showSnackbar = useSnackbar();
    const [deleteTodoMutation] = useTrashItemMutation();
    const deleteTodo = useCallback(({ variables  })=>{
        const { isDeleted , itemId  } = variables;
        deleteTodoMutation({
            variables,
            optimisticResponse: {
                setItemIsDeleted: {
                    item: {
                        id: itemId,
                        nodeId: generateRandomKey(),
                        type: ItemType.ChecklistItem,
                        deletedAt: new Date().toISOString(),
                        isExplicitlyDeleted: isDeleted,
                        __typename: "Item"
                    },
                    __typename: "SetItemIsDeletedPayload"
                }
            }
        }).then(()=>{
            showSnackbar({
                message: `To-do ${isDeleted ? "moved to trash" : "restored"}`
            });
        }).catch(()=>{
            showSnackbar.error();
        });
    }, [
        deleteTodoMutation,
        showSnackbar
    ]);
    return deleteTodo;
}
export default useDeleteTodo;
