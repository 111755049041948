import { useCallback } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { FocusFrom, usePostPageFocus } from "../PostPageFocusContext";
import { usePostPageViewMode } from "../PostPageViewModeContext";
export default function useFocusNoteEditor() {
    const [, setMode] = usePostPageViewMode();
    const [, setFocusFrom] = usePostPageFocus();
    return useCallback(()=>{
        unstable_batchedUpdates(()=>{
            setMode("EDIT");
            setFocusFrom(FocusFrom.NOTE);
        });
    }, [
        setFocusFrom,
        setMode
    ]);
};
