// https://github.com/primer/css
import { themeGet } from "sprout-ui-theme";
import styled from "styled-components";
const Markdown = styled.div`
  color: ${themeGet("colors.black.87")};
  font-size: 16px;
  line-height: 1.5;
  overflow-wrap: break-word;
  word-wrap: break-word;

  > div > *:first-child {
    margin-top: 0 !important;
  }

  > div > *:last-child {
    margin-bottom: 0 !important;
  }

  a {
    color: ${themeGet("colors.blue.500")};
    &:hover {
      text-decoration: underline;
    }
  }

  p,
  ul,
  ol {
    margin-top: 0;
    margin-bottom: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 600;
    line-height: 1.25;
  }

  h1 {
    padding-bottom: 0.3em;
    font-size: 2em;
  }

  h2 {
    padding-bottom: 0.3em;
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.25em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 0.875em;
  }
  h6 {
    font-size: 0.85em;
    color: #6a737d;
  }

  ul,
  ol {
    list-style: revert;
    padding-left: 2em;
  }

  ul ul,
  ul ol,
  ol ol,
  ol ul {
    margin-top: 0;
    margin-bottom: 0;
  }

  li > p {
    margin-top: 16px;
  }

  li + li {
    margin-top: 0.25em;
  }
`;
export default Markdown;
