import { generateRandomKey } from "@sprout/client-lib";
import { useCallback } from "react";
import { ItemType, usePost_SubscribeToNotificationsMutation } from "sprout-graphql";
export default function useSubscribeToNotifications() {
    const [subscribeToNotifications] = usePost_SubscribeToNotificationsMutation();
    return useCallback(({ variables , nodeId , userPreferencesNodeId  })=>{
        return subscribeToNotifications({
            variables,
            optimisticResponse: {
                upsertUserItemPreference: {
                    query: {
                        nodeId: "query",
                        itemById: {
                            id: variables.postId,
                            nodeId,
                            type: ItemType.Post,
                            userPreferences: {
                                nodeId: userPreferencesNodeId || generateRandomKey(),
                                subscribeToNotifications: !variables.subscribeToNotifications,
                                __typename: "UserItemPreference"
                            },
                            __typename: "Item"
                        },
                        __typename: "Query"
                    },
                    __typename: "UpsertUserItemPreferencePayload"
                }
            }
        });
    }, [
        subscribeToNotifications
    ]);
};
