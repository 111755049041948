import { media } from "sprout-ui-theme";
import styled from "styled-components";
import { Button } from "../Button";
const PopoverDialogSaveButton = styled(Button)`
  ${media.down("small")} {
    display: none;
  }
`;
PopoverDialogSaveButton.defaultProps = {
    variant: "yellow"
};
export default PopoverDialogSaveButton;
