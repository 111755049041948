import { useUpdateItemMutation } from "sprout-graphql";
import { useCallback } from "react";
import { useSnackbar } from "sprout-ui-dom/dist/Snackbar";
export default function useUpdateItem() {
    const [updateItem] = useUpdateItemMutation();
    const showSnackbar = useSnackbar();
    return useCallback((variables)=>{
        updateItem({
            variables
        }).catch(()=>{
            showSnackbar.error();
        });
    }, [
        showSnackbar,
        updateItem
    ]);
};
