import { useCallback } from "react";
import { useActionRegistryGlobalHandler } from "@sprout/list-navigation";
import { getIsLargePointerDevice } from "sprout-ui-dom/dist/utils";
import { usePostPageEditor } from "../PostPageEditorContext";
export default function usePostPageTodos({ disabled  }) {
    const { dispatch  } = usePostPageEditor();
    useActionRegistryGlobalHandler("ADD_TODOLIST", useCallback(()=>{
        dispatch({
            type: "SET_EDITOR",
            payload: {
                editor: "CREATE_TODOLIST"
            }
        });
        return true;
    }, [
        dispatch
    ]), {
        skip: disabled
    });
    useActionRegistryGlobalHandler("ADD_TODO", useCallback(()=>{
        dispatch({
            type: "SET_EDITOR",
            payload: {
                editor: "CREATE_TODO",
                /**
           * Trick to create this `TODO` as the last orphan todo.
           */ editorPosition: Number.MAX_SAFE_INTEGER,
                variant: getIsLargePointerDevice() ? "desktop" : "mobile"
            }
        });
        return true;
    }, [
        dispatch
    ]), {
        skip: disabled
    });
};
