import { useCallback } from "react";
import { useSnackbar } from "sprout-ui-dom/dist/Snackbar";
import { useHistory } from "react-router";
import { getItemUrl } from "sprout-lib";
import { usePostPage_ConvertItemTypeMutation } from "sprout-graphql";
export default function useConvertItemToPost() {
    const [convertItemToPost] = usePostPage_ConvertItemTypeMutation();
    const showSnackbar = useSnackbar();
    const history = useHistory();
    return useCallback((variables)=>{
        convertItemToPost({
            variables
        }).then(({ data  })=>{
            var ref;
            const item = data === null || data === void 0 ? void 0 : (ref = data.convertItemType) === null || ref === void 0 ? void 0 : ref.item;
            if (item) history.push(getItemUrl(item));
        }).catch(()=>{
            showSnackbar.error();
        });
    }, [
        convertItemToPost,
        history,
        showSnackbar
    ]);
};
