import { useActionRegistryMultiselectAction } from "@sprout/list-navigation";
import { useCallback, useMemo, useState } from "react";
export default function useMultiselectDialog() {
    const [dialog1, setDialog] = useState();
    const [selectedItemIds, setSelectedItemIds] = useState([]);
    const { startMultiselectAction , stopMultiselectAction  } = useActionRegistryMultiselectAction(useMemo(()=>{
        return {
            escape: dialog1 !== undefined ? ()=>{
                setDialog(undefined);
                return true;
            } : undefined,
            complete: dialog1 === undefined
        };
    }, [
        dialog1
    ]));
    const openDialog = useCallback(({ items , dialog  })=>{
        return startMultiselectAction(()=>{
            setDialog(dialog);
            setSelectedItemIds(items.map((item)=>item.id));
        });
    }, [
        startMultiselectAction
    ]);
    const closeDialog = useCallback((error)=>{
        setDialog(undefined);
        setSelectedItemIds([]);
        stopMultiselectAction(error);
    }, [
        stopMultiselectAction
    ]);
    return {
        dialog: dialog1,
        openDialog,
        closeDialog,
        selectedItemIds
    };
};
