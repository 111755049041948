import useCurrentUser from "@sprout/client-graphql/dist/useCurrentUser";
import { useMemo } from "react";
import getPostAuthorization from "./getPostAuthorization";
export default function usePostAuthorization(post) {
    const currentUser = useCurrentUser();
    const currentUserId = currentUser === null || currentUser === void 0 ? void 0 : currentUser.id;
    const authorization = useMemo(()=>{
        return getPostAuthorization({
            post,
            currentUserId
        });
    }, [
        currentUserId,
        post
    ]);
    return authorization;
};
