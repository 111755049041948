import styled from "styled-components";
import { Flex } from "sprout-ui-primitives";
import { media } from "sprout-ui-theme";
const PopoverDialogContent = styled(Flex)`
  ${media.down("small")} {
    padding: 16px;
  }
`;
PopoverDialogContent.defaultProps = {
    pt: 1,
    pb: 3,
    flexDirection: "column"
};
export default PopoverDialogContent;
