import { DividerText } from "sprout-ui-dom";
import styled from "styled-components";
import { media } from "sprout-ui-theme";
const PostPageDividerText = styled(DividerText)`
  margin-top: 24px;
  margin-bottom: 24px;

  ${media.down("medium")} {
    padding: 0 16px;
  }

  ${media.target("touch")} {
    padding: 0 16px;
  }
`;
export default PostPageDividerText;
