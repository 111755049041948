import { useActionRegistryGlobalHandler } from "@sprout/list-navigation";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
export default function useGoUp(url) {
    const history = useHistory();
    useActionRegistryGlobalHandler("GO_UP", useCallback(()=>{
        history.push(url);
        return true;
    }, [
        history,
        url
    ]));
};
