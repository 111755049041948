function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import generateOptimisticId from "@sprout/client-lib/dist/generateOptimisticId";
import generateRandomKey from "@sprout/client-lib/dist/generateRandomKey";
import { useCallback } from "react";
import { ItemType, PostPageTodoList_ItemFragmentDoc, PostPageTodosAndTodoLists_ItemFragmentDoc, usePostPage_NewTodoMutation } from "sprout-graphql";
import update from "immutability-helper";
import { optimisticPost } from "@sprout/client-components/dist/Post";
function insert(arr, index, item) {
    return [
        ...arr.slice(0, index),
        item,
        ...arr.slice(index)
    ];
}
function getNodes({ nodes , item , afterItemId , afterLastItem  }) {
    /**
   * NOTE: The response from the mutation might already be returned by
   * subscription. If the item is already in the array, it means
   * the subscription has already returned.
   *
   * https://github.com/apollographql/apollo-client/issues/1716
   * https://github.com/apollographql/apollo-client/issues/3062
   */ if (nodes.some((node)=>node.id === item.id)) return nodes;
    let index = 0;
    if (typeof afterItemId === "number") {
        const matchIndex = nodes.findIndex((node)=>node.id === afterItemId);
        index = matchIndex + 1;
    } else if (afterLastItem) index = nodes.length + 1;
    return insert(nodes, index, item);
}
export default function useNewTodo() {
    const [newTodoMutation] = usePostPage_NewTodoMutation();
    return useCallback(({ variables , parentId , parentNodeId , parentType  })=>{
        const { description , afterItemId , afterLastItem  } = variables;
        return newTodoMutation({
            variables,
            optimisticResponse: {
                createItem: {
                    item: _objectSpread({}, optimisticPost, {
                        parentTopicId: parentId,
                        nodeId: generateRandomKey(),
                        id: generateOptimisticId(),
                        url: variables.url || null,
                        description: description || null,
                        completedAt: null,
                        __typename: "Item",
                        /**
               * It does not matter what the position is, since optimistic response will insert
               * it into the right position
               */ position: Number.MAX_SAFE_INTEGER,
                        createdAt: new Date().toISOString()
                    }),
                    __typename: "CreateItemPayload"
                }
            },
            update (proxy, { data  }) {
                var ref;
                const item = data === null || data === void 0 ? void 0 : (ref = data.createItem) === null || ref === void 0 ? void 0 : ref.item;
                if (!item) return;
                if (parentType === ItemType.Post) {
                    const options = {
                        id: parentNodeId,
                        fragment: PostPageTodosAndTodoLists_ItemFragmentDoc,
                        fragmentName: "PostPageTodosAndTodoLists_Item"
                    };
                    const cache = proxy.readFragment(options);
                    proxy.writeFragment(_objectSpread({}, options, {
                        data: update(cache, {
                            todos: {
                                nodes: {
                                    $apply (nodes) {
                                        return getNodes({
                                            nodes,
                                            item,
                                            afterItemId,
                                            afterLastItem
                                        });
                                    }
                                }
                            }
                        })
                    }));
                } else {
                    const options = {
                        id: parentNodeId,
                        fragment: PostPageTodoList_ItemFragmentDoc,
                        fragmentName: "PostPageTodoList_Item"
                    };
                    const cache = proxy.readFragment(options);
                    proxy.writeFragment(_objectSpread({}, options, {
                        data: update(cache, {
                            todos: {
                                nodes: {
                                    $apply (nodes) {
                                        return getNodes({
                                            nodes,
                                            item,
                                            afterItemId,
                                            afterLastItem
                                        });
                                    }
                                }
                            }
                        })
                    }));
                }
            }
        });
    }, [
        newTodoMutation
    ]);
};
