import { useCurrentUser, useSetItemsCompleted } from "@sprout/client-graphql";
import { useActionRegistryGlobalHandler } from "@sprout/list-navigation";
import { useCallback } from "react";
export default function useMarkCompleteGlobalHandler() {
    const currentUser = useCurrentUser();
    const setItemsCompleted = useSetItemsCompleted();
    useActionRegistryGlobalHandler([
        "SET_COMPLETED",
        "SET_INCOMPLETE"
    ], useCallback((items, _event, command)=>{
        items.length;
        setItemsCompleted({
            items: [
                ...items
            ],
            completed: command == "SET_COMPLETED"
        });
        return true;
    }, [
        setItemsCompleted
    ]), {
        skip: !currentUser
    });
};
